<template>
    <div>
        <div class="page-section">
            <div class="page-section__inner gr gr--wrap">
                <div class="col col--1@tablet hide show@tablet"></div>
                <div class="col col--12 col--10@tablet page-section__card">
                    <VHeading level="2" class="page-section__card__title">
                        Knowledgebase
                    </VHeading>
                    <div class="page-section__card__block__full">
                        <div>
                            <div class="filter">
                                <div class="filter__inner">
                                    <div class="filter__inner__content">
                                        <VText size="big" weight="bold">
                                            Filter questions by keyword
                                        </VText>
                                        <VMultiselect
                                                v-model="tags"
                                                :classes="'form__multiselect--beige'"
                                                placeholder="Select a tag.."
                                                :options="faqTags"
                                                @input="updateTags"
                                        ></VMultiselect>
                                    </div>
                                    <div class="filter__inner__content">
                                        <VText size="big" weight="bold">
                                            Jump to a question category
                                        </VText>
                                        <VMultiselect
                                                v-model="category"
                                                :classes="'form__multiselect--beige'"
                                                :multiple="false"
                                                :options="filteredFaqCategories"
                                                :closeOnSelect="true"
                                                placeholder="Select a category.."
                                        ></VMultiselect>
                                    </div>
                                </div>
                            </div>

                            <div
                                    v-for="category in filteredFaqCategories"
                                    :key="`category-${category.id}`"
                                    :ref="`category-${category.id}`"
                            >
                                <VHeading
                                        level="3"
                                        class="subtitle subtitle--big page-section__card__subtitle"
                                >
                                    {{ category.name }}
                                </VHeading>
                                <div class="faq">
                                    <div
                                            v-for="faq in categoryFaqs(category)"
                                            :key="`faq-${faq.id}`"
                                            :ref="`faq-${faq.id}`"
                                            class="faq__inner"
                                            :class="{ faq__inner__active: isActiveFaq(faq) }"
                                    >
                                        <div
                                                class="faq__inner__item"
                                                :class="{ active: isActiveFaq(faq) }"
                                                @click="toggle(faq)"
                                        >
                                            <VText classes="p--semibold faq-question">
                                                {{ faq.question }}
                                            </VText>

                                            <VText
                                                    size="big"
                                                    weight="semibold"
                                                    key="minus"
                                                    v-if="isActiveFaq(faq)"
                                            >
                                                -
                                            </VText>
                                            <VText size="big" weight="semibold" key="plus" v-else>
                                                +
                                            </VText>
                                        </div>
                                        <div class="faq__inner__content" v-show="isActiveFaq(faq)">
                                            <VText classes="cursor-pointer faq-answer">
                                                <div class="no-style" v-html="faq.answer"></div>
                                            </VText>
                                            <div
                                                    v-if="isAuthenticated"
                                                    class="faq__inner__content__bar"
                                            >
                                                <VText size="small" :italic="true">
                                                    Was this answer helpful?
                                                </VText>
                                                <div>
                                                    <img
                                                            src="@/assets/imgs/icons/icon-like.svg"
                                                            class="like cursor-pointer"
                                                            alt="like icon"
                                                            @click.stop="leftIsHelpful(faq, true)"
                                                    />
                                                    <img
                                                            src="@/assets/imgs/icons/icon-dislike.svg"
                                                            class="dislike cursor-pointer"
                                                            alt="dislike icon"
                                                            @click.stop="leftIsHelpful(faq, false)"
                                                    />
                                                </div>
                                                <div class="faq__inner__content__bar__tags">
                                                    <VText size="small" classes="tagged-text" :italic="true">
                                                        Tagged with
                                                    </VText>
                                                    <div class="keyword__wrapper">
                                                        <div
                                                                v-for="(tag, index) in faq.tags"
                                                                :key="`tag-${index}`"
                                                                class="keyword"
                                                        >
                                                            {{ tag.name }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                    v-if="isAuthenticated"
                                                    class="faq__inner__content__feedback"
                                            >
                                                <div class="form__group faq__textarea">
                                                    <VTextarea
                                                            v-model="feedback"
                                                            rows="7"
                                                            name="text"
                                                            placeholder="Send us your feedback for this question…"
                                                    ></VTextarea>

                                                    <VButton
                                                            color="outline-black"
                                                            classes="faq__button"
                                                            @click.stop="leftFeedback(faq)"
                                                    >
                                                        Submit
                                                    </VButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col--1@tablet hide show@tablet"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapGetters} from 'vuex';

    import {
        FETCH_FAQ_CATEGORIES,
        LEFT_IS_FAQ_HELPFUL,
        LEFT_FAQ_FEEDBACK,
        FETCH_FAQ_TAGS,
        FETCH_FAQS
    } from '@/store/actions.type';

    import {OPEN_ALERT} from '@/store/mutations.type';

    export default {
        name: 'FaqPage',
        data() {
            return {
                tags: [],
                category: null,
                activeFaq: null,
                feedback: null
            };
        },
        computed: {
            ...mapGetters(['isAuthenticated']),
            ...mapState({
                faqs: state => state.faq.faqs,
                faqTags: state => state.faq.tags,
                faqCategories: state => state.faq.categories
            }),
            filteredFaqCategories() {
                return this.faqCategories.filter(category => {
                    return this.categoryFaqs(category).length;
                });
            }
        },
        watch: {
            category: 'updateCategory',
            activeFaq: 'setActiveFaq'
        },
        async mounted() {
            await this.fetchTags();
            await this.fetchCategories();
            await this.setTagParams();
            await this.fetchData();
            await this.setActiveFaqParams();
        },
        methods: {
            async fetchData() {
                const tags = this.tags.map(tag => tag.id);
                await this.$store.dispatch(FETCH_FAQS, {tags: tags});
            },
            async fetchCategories() {
                await this.$store.dispatch(FETCH_FAQ_CATEGORIES);
            },
            async fetchTags() {
                await this.$store.dispatch(FETCH_FAQ_TAGS);
            },
            async leftFeedback(faq) {
                await this.$store.dispatch(LEFT_FAQ_FEEDBACK, {
                    faq: faq.id,
                    feedback: this.feedback
                });

                this.toggle();

                this.$store.commit(OPEN_ALERT, {
                    type: 'success',
                    title: 'Thank you for your feedback',
                    content: `We will review and make any appropriate amends to the FAQ. We may also contact you to
                    discuss or clarify any concerns.

                    Regards Takwimu Support`
                });
            },
            async leftIsHelpful(faq, isHelpful) {
                await this.$store.dispatch(LEFT_IS_FAQ_HELPFUL, {
                    isHelpful: isHelpful,
                    faq: faq.id
                });

                this.toggle();

                this.$store.commit(OPEN_ALERT, {
                    type: 'success',
                    title: 'Thank you for your feedback',
                    content: 'Regards Takwimu Support'
                });
            },
            setTagParams() {
                const tagParams = this.$route.query.tags;

                if (!tagParams) return;
                this.tags = this.faqTags.filter(tag =>
                    tagParams.includes(String(tag.id))
                );
            },
            toggle(faq = null) {
                if (this.activeFaq === faq) {
                    this.activeFaq = null;
                    return;
                }

                this.feedback = '';
                this.activeFaq = faq;
            },
            updateTags() {
                const tags = this.tags.map(tag => tag.id);
                this.$router.replace({
                    name: 'knowledgebase',
                    query: {...this.$route.query, tags: tags}
                });
                this.fetchData();
            },
            updateCategory() {
                if (!this.category) return;

                const category = this.$refs[`category-${this.category.id}`][0];
                category.scrollIntoView({behavior: 'smooth'});
            },
            categoryFaqs(category) {
                return this.faqs.filter(item => item.category && item.category.id === category.id);
            },
            isActiveFaq(faq) {
                return this.activeFaq && this.activeFaq.id === faq.id;
            },
            setActiveFaqParams() {
                let {activeFaq} = this.$route.query;

                if (!activeFaq) return;

                this.activeFaq = this.faqs.find(item => item.id === parseFloat(activeFaq));
            },
            setActiveFaq() {
                if (!this.activeFaq) return;

                this.$router.replace({
                    name: 'knowledgebase',
                    query: {
                        ...this.$route.query,
                        activeFaq: this.activeFaq.id
                    }
                })
                    .catch(err => {
                    });

                this.$nextTick(() => {
                    const faq = this.$refs[`faq-${this.activeFaq.id}`][0];
                    faq.scrollIntoView({behavior: 'smooth'});
                });
            }
        }
    };
</script>



<style scoped>
    /deep/ .multiselect__placeholder {
        margin-bottom: 10px;
    }

    /deep/ .multiselect__arrow {
        position: absolute;
        right: 15px;
        top: 15px;
    }
</style>

<style>
    .no-style {
        all: unset !important;
    }

    .no-style td {
        padding: 4px;
    }

    .no-style p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
</style>
